/** @jsx jsx */
import { Heading, jsx, Themed } from 'theme-ui'

function PostTitle({ title, as = Themed.h1, ...props }) {
  return (
    <Heading as={as} className="p-name" sx={{ fontSize: 5, m: 0 }} {...props}>
      {title}
    </Heading>
  )
}

export default PostTitle
