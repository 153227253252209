/** @jsx jsx */
import { Container, Flex, jsx } from 'theme-ui'
import Footer from './Footer'
import Header from './Header'

function Layout({ siteMetadata, children }) {
  return (
    <Flex mx="auto" sx={{ flexDirection: 'column', minHeight: '100vh' }}>
      <Header siteMetadata={siteMetadata} />

      <Container
        as="main"
        sx={{ variant: 'layout.container', flex: '1 1 auto' }}
      >
        {children}
      </Container>

      <Footer siteMetadata={siteMetadata} />
    </Flex>
  )
}

export default Layout
