/** @jsx jsx */
import { Fragment } from 'react'
import { jsx, Text } from 'theme-ui'
import { usePostDate } from '../../hooks/usePostDate'
import ScreenReaderText from '../ScreenReader/ScreenReaderText'

function PostDate({ date, ...props }) {
  const { iso, display } = usePostDate(date)

  return (
    <Fragment>
      <ScreenReaderText>Published on </ScreenReaderText>
      <Text
        as="time"
        className="dt-published"
        dateTime={iso}
        sx={{ fontSize: 1, fontWeight: 'bold' }}
        {...props}
      >
        {display}
      </Text>
    </Fragment>
  )
}

export default PostDate
