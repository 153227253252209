/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

function ScreenReaderText({ children, as = 'span', ...props }) {
  return (
    <Box
      {...props}
      as={as}
      sx={{
        position: 'absolute',
        clip: 'rect(1px, 1px, 1px, 1px)',
        clipPath: 'inset(50%)',
        height: '1px',
        width: '1px',
        margin: '-1px',
        padding: 0,
        border: 0,
        overflow: 'hidden',
        wordWrap: 'normal !important',
      }}
    >
      {children}
    </Box>
  )
}

export default ScreenReaderText
