import { graphql } from 'gatsby'
import BlogList from '../components/BlogList'

export default BlogList

export const query = graphql`
  query BlogListQuery {
    site {
      siteMetadata {
        title
        author
      }
    }
    allBlogPost(
      filter: { draft: { eq: false } }
      sort: { fields: [date, title], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          id
          excerpt
          slug
          title
          date
          tags
        }
      }
    }
  }
`
