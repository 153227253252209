/** @jsx jsx */
import { useMemo } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'

const Footer = ({ siteMetadata }) => {
  const year = useMemo(() => new Date().getFullYear(), [])

  return (
    <Container as="footer" sx={{ variant: 'layout.footer' }}>
      <Flex>
        <Box sx={{ mx: 'auto' }} />
        <Box sx={{ p: 2 }}>
          © {year} {siteMetadata.author}
        </Box>
      </Flex>
    </Container>
  )
}

export default Footer
