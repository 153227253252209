/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, jsx, Themed } from 'theme-ui'
import PostAuthor from '../BlogPost/PostAuthor'
import PostDate from '../BlogPost/PostDate'
import PostTitle from '../BlogPost/PostTitle'

function PostListItem({ data, siteMetadata }) {
  return (
    <Box as="article" sx={{ mb: 4 }} className="h-entry">
      <Box as="header">
        <Link
          className="u-url"
          to={data.slug}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            ':hover,:focus': {
              color: 'primary',
              textDecoration: 'none',
            },
          }}
        >
          <PostTitle as={Themed.h2} title={data.title} />
        </Link>
        <PostAuthor siteMetadata={siteMetadata} />
        <PostDate date={data.date} />
      </Box>

      <Box as="section" className="p-summary">
        <Themed.p>{data.excerpt}</Themed.p>
      </Box>
    </Box>
  )
}

export default PostListItem
