import { DateTime } from 'luxon'
import { useMemo } from 'react'

export function usePostDate(date, formatOptions = DateTime.DATE_FULL) {
  return useMemo(() => {
    const datetime = DateTime.fromISO(date)
    const iso = datetime.toISO()
    const display = datetime.toLocaleString(formatOptions)
    return { iso, display }
  }, [date, formatOptions])
}
