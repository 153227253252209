/** @jsx jsx */
import { useMemo } from 'react'
import { jsx, Themed } from 'theme-ui'
import Layout from '../Layout'
import SEO from '../SEO'
import PostListItem from './PostListItem'

function BlogList({ data, pageContext }) {
  const { permalink } = pageContext
  const posts = useMemo(
    () => data.allBlogPost.edges.map(({ node }) => node),
    [data.allBlogPost.edges]
  )

  return (
    <Themed.root>
      <SEO url={permalink} title={`Blog`} />

      <Layout siteMetadata={data.site.siteMetadata}>
        <ul sx={{ listStyle: 'none', m: 0, p: 0 }} className="h-feed">
          {posts.map((post) => (
            <PostListItem
              key={post.id}
              data={post}
              siteMetadata={data.site.siteMetadata}
            />
          ))}
        </ul>
      </Layout>
    </Themed.root>
  )
}

export default BlogList
