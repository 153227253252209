/** @jsx jsx */
import { jsx } from 'theme-ui'
import InertLink from '../Link/InertLink'
import ScreenReaderText from '../ScreenReader/ScreenReaderText'

function PostAuthor({ siteMetadata, as = 'span', ...props }) {
  return (
    <ScreenReaderText as={as} sx={{ a: { display: 'inline' } }} {...props}>
      by
      <InertLink className="p-author h-card" href="/" rel="author">
        {siteMetadata.author}
      </InertLink>
    </ScreenReaderText>
  )
}

export default PostAuthor
