/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Container, Flex, Image, jsx, NavLink, Text } from 'theme-ui'

function Header({ siteMetadata }) {
  return (
    <Container as="header" sx={{ variant: 'layout.header' }}>
      <Flex
        sx={{
          justifyContent: ['flex-start', 'space-between'],
          alignItems: 'center',
        }}
      >
        <Box
          as={Link}
          to="/"
          title="Home"
          sx={{ display: 'block', height: [48, 64], mr: 2 }}
        >
          <Image
            alt={`${siteMetadata.author}'s Avatar`}
            src="/avatar-256.png"
            sx={{ maxHeight: [48, 64] }}
          />
          <Text
            as="span"
            sx={{
              position: 'absolute',
              width: 1,
              height: 1,
              overflow: 'hidden',
              top: -9999,
            }}
          >
            Home
          </Text>
        </Box>

        <Flex sx={{ flexDirection: 'row' }}>
          <NavLink
            as={Link}
            to="/blog"
            sx={{ display: 'block', minWidth: 'auto', px: [2, 3], py: [1, 3] }}
          >
            Blog
          </NavLink>

          <NavLink
            as={Link}
            to="/"
            sx={{ display: 'block', minWidth: 'auto', px: [2, 3], py: [1, 3] }}
          >
            About
          </NavLink>

          <NavLink
            href="https://go.muniftanjim.dev/subscribe"
            sx={{ display: 'block', minWidth: 'auto', px: [2, 3], py: [1, 3] }}
            target="_blank"
          >
            Subscribe
          </NavLink>
        </Flex>
      </Flex>
    </Container>
  )
}

export default Header
